<template>
  <div>
    <!--    合同-->
    <div v-if="hasAuthority('brand_hub_contract_list')">
      <searchPage @handleQuery="handleQuery" :type="'contract'" @handelAdd="handelAdd"></searchPage>
      <div class="list-table default-table radius_bg">

        <el-table :row-style="{height:'20px'}" :data="dataList" ref="multipleTable" row-key="id" highlight-current-row
                  v-loading="isLoading" border>
          <el-table-column align="center" label="序号" type="index" width="60"></el-table-column>
          <el-table-column align="center" prop="name" show-overflow-tooltip label="合同名">
            <template slot-scope="{row}">
         <span style="width: 100%;cursor: pointer;display: block" class="title" @click="handleEdit(row)">
           {{ row.name }}
         </span></template>
          </el-table-column>
          <el-table-column align="center" prop="customer_name" show-overflow-tooltip label="公司名">
            <template slot-scope="{row}">
         <span class="subtitle">
           {{ row.customer_name }}
         </span></template>
          </el-table-column>
          <el-table-column align="center" prop="brand_name" label="品牌" width="120" show-overflow-tooltip>
            <template slot-scope="{row}">
         <span class="brand">
           {{ row.brand_name }}
         </span></template>
          </el-table-column>
          <el-table-column align="center" width="130" prop="due_date" label="合同有效截止日期" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" width="110" prop="created_at" label="上传日期"
                           show-overflow-tooltip></el-table-column>
          <el-table-column align="center" width="110" prop="submit_name" label="负责人"
                           show-overflow-tooltip></el-table-column>

          <el-table-column align="center" prop="remark" label="合同备注" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" align="center" width="200">
            <template slot-scope="{ row }">
              <el-button size="default" type="text" @click="handleEdit(row)" class="el-icon-s-claim">详情/修改
              </el-button>
              <el-button size="default" type="text" @click="handleDel(row)" class="el-icon-delete">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-row>
        <el-col :span='24'>
          <Pagination :limit.sync='pagingData.page_size'
                      :page.sync='pagingData.current_page'
                      :total='pagingData.total'
                      @pagination='getList'/>
        </el-col>
      </el-row>
    </div>
    <el-dialog :visible.sync='dialogStore'
               :title="`合同信息-${dialogType === 'ADD' ? '添加' : hasAuthority('brand_hub_contract_update')?'修改':'详情'}合同`"
               width="1200" :before-close="closeFun" v-if="dialogStore"
               center>
      <el-row>
        <el-col :span="10" :offset="1">
          <el-upload
              v-loading="uploadLoading"
              style="width: 100%;margin-top: 30px"
              class="upload-demo"
              :on-success="importSuccess"
              :headers="uploadHeaders"
              name="file"
              :show-file-list="false"
              :file-list="file"
              :before-upload="beforeUpload"
              drag
              action="/admin_api/upload/file"
              multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em>（最大支持10M大小）</div>
            <div class="el-upload__tip" slot="tip"></div>
          </el-upload>
          附件列表 (共{{ fileData.length }}份)
          <div v-for="(item,index) in fileData" :key="index">
            <a target="_blank" :href="item.full_path" :title="item.title" style="margin-right: 10px">
              {{ item.filename }} </a>
            <div v-if="hasAuthority('brand_hub_contract_update')" style="color: red;cursor: pointer"
                 @click="delAttachment(item)"
                 class="el-icon-delete"></div>

          </div>
        </el-col>
        <el-col :span="12">
          <el-form ref='formStore' :model='formStore' :rules='rules' label-poionsit='right'
                   label-width='140px'>
            <el-form-item label="公司名" prop="customer_id">
              <el-select clearable v-model="formStore.customer_id" filterable
                         :disabled="!hasAuthority('brand_hub_contract_update')" @change="setSelectValue('customer')"
                         style="width: 100%" placeholder="请选择">
                <el-option
                    v-for="item in nameOptions"
                    :key="item.name"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label='品牌' prop='brand_id'>
              <el-select clearable v-model="formStore.brand_id" :disabled="!hasAuthority('brand_hub_contract_update')"
                         @change="setSelectValue('brand')" filterable
                         default-first-option style="width: 100%" placeholder="请选择">
                <el-option
                    v-for="item in brandOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label='合同名' prop='name'>
              <el-input v-model='formStore.name' :disabled="!hasAuthority('brand_hub_contract_update')"
                        placeholder='请输入合同名'/>
            </el-form-item>
            <el-form-item label='合同有效截止日期' prop='due_date'>
              <el-date-picker :disabled="!hasAuthority('brand_hub_contract_update')"
                              style="width: 100%;"
                              v-model="formStore.due_date"
                              type="date"
                              value-format="yyyy-MM-dd"
                              placeholder="选择截止日期">
              </el-date-picker>
            </el-form-item>

            <el-form-item label="备注" prop="remark">
              <el-input type="textarea" maxlength="500" rows="5" v-model="formStore.remark"
                        :disabled="!hasAuthority('brand_hub_contract_update')" placeholder="请输入备注信息"
                        clearable></el-input>
            </el-form-item>
            <el-form-item label='负责人' prop='submit_id'>
              <el-select
                  :disabled="!hasAuthority('brand_hub_contract_update')" @change="setSelectValue('submit')"
                  v-model="formStore.submit_name"
                  filterable
                  remote
                  reserve-keyword
                  placeholder="输入关键字，搜索负责人"
                  :remote-method="remoteMethod"
                  :loading="selectLoading">
                <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.nickname"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <span slot='footer' class='dialog-footer'>
        <el-button @click='dialogStore = false'>取 消</el-button>
        <el-button type='primary' v-if="hasAuthority('brand_hub_contract_update')"
                   @click='handelStore'>确 定</el-button>
      </span>
    </el-dialog>
<!--    <el-dialog-->
<!--        :visible.sync='downloadLoading'-->
<!--        width='50%'-->
<!--        title="附件列表"-->
<!--        :show-close='loadingEnd'-->
<!--    >-->

<!--      <div class="updateList">-->
<!--        <div class='left'>-->
<!--          <img style="width: 300px" :src="listImg">-->
<!--        </div>-->
<!--        <div class='right'>-->
<!--          <div class="list" v-for="item in listUpdate" :key="item.id">-->
<!--            <div v-show="item.name.indexOf('png')||item.name.indexOf('jpg')">-->
<!--              <el-image-->
<!--                  :title="item.name"-->
<!--                  style="width: 100px; height: 100px"-->
<!--                  :src="item.url"-->
<!--                >-->
<!--              </el-image>-->
<!--              <el-icon class="el-icon-download"></el-icon>-->
<!--            </div>-->
<!--          </div>-->

<!--        </div>-->
<!--      </div>-->

<!--      <br/>-->

<!--    </el-dialog>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'
import searchPage from '@/pages/lb/brand/searchPage'
import { getToken } from '@/utils/auth'
import _ from 'lodash'

import listImg from '@/assets/imgs/list.png'

const rules = {
  proj_name: [
    { required: true, message: '请输入项目名称', trigger: 'blur' },
    { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
  ],
  name: [{ required: true, message: '请填写合同名', trigger: 'blur' }],
  customer_id: [{ required: true, message: '请填写公司', trigger: 'blur' }],
  brand_id: [{ required: true, message: '请填写品牌', trigger: 'blur' }],
  due_date: [{ required: true, message: '请选择合同有效截止日期', trigger: 'blur' }]
}
export default {
  name: 'investment',
  data() {
    return {
      listImg: listImg,
      dataList: [],
      // downloadLoading: false,
      isLoading: false,
      dialogStore: false,
      formStore: {},
      product: [],
      rules: rules,
      searchData: {},
      dialogType: 'ADD',
      nameOptions: [],
      uploadHeaders: { 'HYPERF-SESSION-ID': null },
      file: [],
      maxSize: 5,//文件最大的 5 M
      pagingData: {
        page_size: 15,
        current_page: 1,
        total: 0
      },
      fileData: [],
      brandOptions: [],
      selectLoading: false,
      options: [],
      uploadLoading: false,
      loadingEnd: false,
      listUpdate: []
    }
  },

  components: {
    ExportBtn, searchPage
  },
  computed: {
    ...mapGetters(['userPermissions', 'user_info'])
  },
  methods: {
    // infoBtn(row) {
    //   this.downloadLoading = true
    //   this.listUpdate = row.attachments
    // },
    closeFun() {
      this.formStore = {}
      this.getList()
      this.dialogStore = false
    },
    async remoteMethod(query) {
      if (query !== '') {
        this.selectLoading = true
        this.options = await this.$api.selectSubmit({ nickname: query })
        this.selectLoading = false
      } else {
        this.options = []
      }
    },
    async delAttachment(item) {
      if (this.dialogType === 'ADD') {
        let index = _.findIndex(this.fileData, item)
        this.fileData.splice(index, 1)
      } else {
        this.$confirm(`确认删除附件【${item.filename}】吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let id = await this.$api.delContractFile({
            contract_id: this.formStore.contract_id,
            attachment_id: item.attachment_id
          })
          if (id) {
            this.$message.success('删除成功')
            let index = _.findIndex(this.fileData, item)
            this.fileData.splice(index, 1)
          }
        })
      }
    },
    async getBrand() {
      let data = await this.$api.selectCustomerBrands({ customer_id: this.formStore.customer_id })
      this.brandOptions = data
    },
    setSelectValue(type) {

      if (type === 'customer') {
        let option = this.nameOptions.find(item => item.id === this.formStore.customer_id)
        this.formStore.customer_id = option.id
        this.formStore.customer_name = option.name
        this.getBrand()
        delete this.formStore?.brand_id
      } else if (type === 'brand') {
        let option = this.brandOptions.find(item => item.id === this.formStore.brand_id)
        this.formStore.brand_id = option.id
        this.formStore.brand_name = option.name
      } else if (type === 'submit') {
        if (this.formStore.submit_name) {
          let option = this.options.find(item => item.id === this.formStore.submit_name)
          if (option) {
            this.formStore.submit_id = option.id
            this.formStore.submit_name = option.nickname
          }
        }
      }

    },
    handleChange(file) {
      this.file = [file]
    },
    beforeUpload(file) {
      this.uploadLoading = true
      const isLt10M = file.size / 10240  / 10240  < this.maxSize
      if (!isLt10M) {
        this.$message.error(`导入文件大小不能超过 ${this.maxSize}MB!`)
        this.uploadLoading = false
      }

      this.uploadHeaders['HYPERF-SESSION-ID'] = getToken()

      return isLt10M
    },
    importSuccess(response) {
      this.uploadLoading=true
      if (response.code === 200) {
        this.$notify.success('上传成功，请预览数据后确认提交')
        this.submitted = []
        this.loadingSubmit = false
        this.showProgress = false
        this.fileData = [...this.fileData, ...response.data]

        this.formStore.name === undefined ? this.$set(this.formStore, 'name', this.fileData[0].title) : ''
        this.uploadLoading = false
      } else {
        this.$notify.warning(response.msg)
      }
    },
    handleQuery(select) {
      this.searchData = select
      this.getList()

    },
    handleEdit(row) {
      this.getSelectList()

      this.dialogType = 'Edit'

      this.formStore = {
        name: row.name,
        remark: row.remark,
        customer_id: row.customer_id,
        brand_name: row.brand_name,
        brand_id: row.brand_id,
        customer_name: row.customer_name,
        submit_id: row.submit_id,
        submit_name: row.submit_name,
        due_date: row.due_date,
        contract_id: row.id
      }
      this.fileData = row.attachments.map((i) => {
        return { full_path: i.url, filename: i.name, attachment_id: i.id }
      })
      this.getBrand()

      setTimeout(() => {
        this.dialogStore = true
      }, 200)


    },
    async handleDel(row) {
      let data =
          {
            contract_id: row.id,
            customer_id: row.customer_id
          }
      this.$confirm(`确认删除合同名为：【${row.name}】的数据吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let id = await this.$api.delContract(data)
        if (id) {
          this.$message.success('删除成功！')
          this.getList()
        } else {
          this.$message.error('未知错误,请联系管理员')
        }
      })
    },
    handleSearchCondition() {
      let cond = {}
      if (this.searchData?.customer_id !== '') {
        cond.customer_id = this.searchData.customer_id
      } else {
        delete cond.fans_total
      }
      if (this.searchData?.brand_id !== '') {
        cond.brand_id = this.searchData.brand_id
      } else {
        delete cond.brand_id
      }
      if (this.searchData?.contract_id !== '') {
        cond.contract_id = this.searchData.contract_id
      } else {
        delete cond.contract_id
      }
      this.pagingData.page_size ? cond['page_size'] = this.pagingData.page_size : 10
      this.pagingData.current_page ? cond['current_page'] = this.pagingData.current_page : 10
      return cond
    },
    async getList() {
      this.isLoading = true
      this.dataList = []
      let searchData = this.handleSearchCondition()
      let { list, page_info } = await this.$api.getContract(searchData)
      this.dataList = list
      this.pagingData.current_page = page_info.current_page
      this.pagingData.page_size = page_info.page_size
      this.pagingData.total = page_info.total
      this.isLoading = false
    },
    handelAdd() {
      this.dialogType = 'ADD'
      this.getSelectList()
      let userInfo = JSON.parse(localStorage.getItem('user_info'))
      this.$set(this.formStore, 'submit_id',userInfo.id)
      this.$set(this.formStore, 'submit_name', userInfo.nickname)
      this.dialogStore = true
    },
    async getSelectList() {
      let data = await this.$api.selectCustomList()
      this.nameOptions = data



    },
    handelStore() {
      this.$refs.formStore.validate(async (valid) => {
        if (valid) {
          if (this.fileData.length != 0) {
            this.formStore.attachments = this.fileData.map(i => {
              return { name: i.filename, url: i.full_path }
            })
          }
          if (!this.formStore.submit_name) {
            let userInfo = JSON.parse(localStorage.getItem('user_info'))
            this.formStore.submit_id = userInfo.id
            this.formStore.submit_name = userInfo.nickname
          }
          const params = this.formStore
          if (this.dialogType === 'ADD') {
            let id = await this.$api.addContract(params)
            if (id) {
              this.$message.success('新增成功！')
              this.closeFun()
            } else {
              this.$message.error('未知错误,请联系管理员')
            }

          } else {
            console.log(params)
            let id = await this.$api.updateContract(params)
            if (id) {
              this.$message.success('修改成功！')
              this.closeFun()
            } else {
              this.$message.error('未知错误,请联系管理员')
            }

          }

          await this.getList()
        }
      })
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style lang="scss" scoped>
@import "@/pages/lb/style/main.scss";


::v-deep.list-table .el-table thead {
  background: #eee;
  color: #666;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

::v-deep.list-table .el-table__header-wrapper .el-table__header thead tr th {
  background: #EBEEF5;
  padding: 0 0 !important;
}

::v-deep.list-table .el-table__fixed-header-wrapper .el-table__header thead tr th {
  background: rgba(248, 248, 248, 1);
  padding: 0 0 !important;
}

::v-deep.list-table th, .list-table td {
  /*padding: 4px 0;*/

}

::v-deep.list-table div.el-table td .cell {
  height: auto !important;
  line-height: 20px;
  font-weight: normal;
  font-size: 12px;

}

/*el-table__cell*/
::v-deep.list-table thead th .cell {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-weight: 800;
  color: #0C0C0C;
}

::v-deep.list-table-child thead tr th .cell {
  height: 30px !important;
  line-height: 30px !important;
  font-size: 12px !important;
  /*font-weight: 800 !important; !**!*/
  color: #0C0C0C !important;
}

::v-deep.list-table thead th.is-leaf .cell {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  font-weight: 800;
  color: #0C0C0C;
}


::v-deep.list-table-child thead th.is-leaf .cell {
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  font-weight: normal !important;
  color: #0C0C0C;

}

::v-deep.list-table-child .el-table__header-wrapper .el-table__header thead tr th {
  background: #fbf2f5;
  padding: 0 0 !important;
}

::v-deep.list-table .el-table thead tr, .list-table .el-table thead th {
  background: rgb(255, 255, 255) !important;
  padding: 10px 0 !important;
  color: rgba(50, 50, 50, 1);
  font-size: 14px;
  font-weight: normal;

}

::v-deep.list-table .el-table--striped .el-table__body tr.el-table__row--striped td {
  background: #EBEEF5;
}

</style>
