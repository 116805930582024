<template>
  <el-form class="radius_bg" ref='elForm' :model='formData' label-width='80' size='mini' inline>
    <!--    <el-form-item label="合同" v-if="type==='contract'">-->
    <!--      <el-input placeholder="请输入合同名" v-model="formData.company" @change="handleQuery" clearable/>-->
    <!--    </el-form-item>-->
    <el-form-item label='合同' v-if="type==='contract'">
      <el-select clearable @focus="getSelectList('contract')" @change='handleQuery' v-model='formData.contract_id'
                 filterable
                 placeholder='请选择合同名'>
        <el-option
          v-for='item in contractOptions'
          :key='item.name'
          :label='item.name'
          :value='item.id'>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label='品牌' v-if="type==='contract'||type==='brand'">
      <!--      selectBrand-->
      <el-select clearable @focus="getSelectList('brand')" @change='handleQuery' v-model='formData.brand_id' filterable
                 placeholder='请选择'>
        <el-option
          v-for='item in brandOptions'
          :key='item.name'
          :label='item.name'
          :value='item.id'>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label='公司名' v-if="type!='brand'">
      <el-select clearable @focus="getSelectList('name')" @change='handleQuery' v-model='formData.customer_id'
                 filterable
                 placeholder='请选择'>
        <el-option
          v-for='item in nameOptions'
          :key='item.name'
          :label='item.name'
          :value='item.id'>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label='负责人' v-if="type==='custom'">
      <el-select clearable @focus="getSelectList('subName')" @change='handleQuery' v-model='formData.submit_id'
                 filterable placeholder='请选择'>
        <el-option
          v-for='item in subOptions'
          :key='item.submit_name'
          :label='item.submit_name'
          :value='item.submit_id'>
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button style='margin-left: 10px' @click='handleQuery' type='primary' icon='el-icon-search'>查询</el-button>
      <el-button
        v-if="userPermissions.indexOf('brand_hub_brand_create') !== -1||userPermissions.indexOf('brand_hub_customer_create') !== -1||userPermissions.indexOf('brand_hub_contract_create') !== -1"
        size='mini' type='primary' @click='handelAdd' icon='el-icon-plus'>
        新增{{ type === 'contract' ? '合同' : type == 'brand' ? '品牌' : '客户' }}
      </el-button>
    </el-form-item>
    <el-form-item v-if="type==='brand'">
      <el-button size='mini' @click='routerPage("product_custom")' icon='el-icon-suitcase'>
        客户管理
      </el-button>
      <el-button size='mini' @click='routerPage("product_contract")' icon='el-icon-notebook-2'>
        合同管理
      </el-button>
      <!--   销售查询    -->
      <brand-search-sale v-if="userPermissions.indexOf('brand_hub_brand_sale') !== -1" style='margin-left: 10px;' />
    </el-form-item>
  </el-form>

</template>

<script>
import { mapGetters } from 'vuex'
import BrandSearchSale from './components/SearchSale.vue'

export default {
  name: 'searchPage',
  components: { BrandSearchSale },
  data() {
    return {
      formData: {},
      contractOptions: [],
      nameOptions: [],
      subOptions: [],
      brandOptions: []
    }
  },
  props: {
    type: {
      type: String
    }
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  methods: {
    async getSelectList(type) {
      if (type === 'name') {
        let data = await this.$api.selectCustomList()
        this.nameOptions = data
      } else if (type === 'subName') {
        let data = await this.$api.selectCustomSubmit()
        this.subOptions = data
      } else if (type === 'brand') {
        let data = await this.$api.selectBrand()
        this.brandOptions = data
      } else if (type === 'contract') {
        let data = await this.$api.selectContract()
        this.contractOptions = data
      }
    },
    handleQuery() {
      this.$emit('handleQuery', this.formData)
    },
    routerPage(name) {
      this.$router.push({
        name: name
      })
    },
    handelAdd() {
      this.$emit('handelAdd')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/pages/lb/style/main.scss";

</style>

