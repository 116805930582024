<template>
  <div style='display: inline;'>
    <el-button size='mini' icon='el-icon-s-custom' @click='dialog=true'>
      销售查询
    </el-button>

    <el-dialog title='查询销售' :visible.sync='dialog' width='500px' center>
      <p style='text-align: center;'>数据来源：依据星广汇系统内该品牌广告订单最新合作的销售</p>
      <div style='text-align: center;'>
        <el-input v-model='brand_name' placeholder='请输入品牌名称' size='medium' style='width: 300px;'>
          <el-button slot='append' type='primary' icon='el-icon-search' @click='handleSearch' />
        </el-input>
      </div>
      <template v-if='brand_name&&searched'>
        <el-result v-if='sale_name' :title='sale_name'>
          <template slot='icon'>
            {{ '' }}
          </template>
          <template slot='subTitle'>
            {{ sale_name }} 于 {{ $utils.parseTime(sale_time, '{y}/{m}/{d}') }} 与 {{ brand_name }} &nbsp;有过合作订单
          </template>
        </el-result>
        <el-empty v-else description='未查询到匹配的销售' />
      </template>
      <el-empty v-else-if='brand_name' description='请点击查询' />
      <el-empty v-else description='请输入品牌' />
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'BrandSearchSale',
  data() {
    return {
      dialog: false,
      searched: false,
      brand_name: null,
      sale_name: null,
      sale_time: null
    }
  },
  watch: {
    dialog: function(_) {
      if (_) {
        this.searched = false
        this.brand_name = null
      }
    },
    brand_name: function() {
      this.sale_name = null
      this.sale_time = null
      this.searched = false
    }
  },
  methods: {
    handleSearch: async function() {
      const data = await this.$api.getBrandSale({ brand_name: this.brand_name })
      this.sale_name = data.sale_name || false
      this.sale_time = data.created_timestamp || ''
      this.searched = true
    }
  }
}
</script>

<style scoped>

</style>
